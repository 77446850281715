.reports-marker {
  width: 50px;
  cursor: pointer;
  opacity: 1;
  height: auto;
}

.marker-new,
.marker-report_new,
.marker-planning,
.marker-execution,
.marker-invalid,
.marker-done {
  width: 40px;
  height: 50px;
}

.marker-verification,
.marker-accepted,
.marker-rejected,
.marker-inactive,
.marker-report_verification,
.marker-report_accepted,
.marker-report_rejected,
.marker-report_inactive {
  width: 24px;
  height: 24px;
}

.reports-cluster {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(145, 0, 85, 0.5);
  background: white;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(84, 84, 84, 0.5);
}

.reports-cluster__inner {
  border: 3px solid rgba(207, 10, 125, 0.4);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

#deckgl-wrapper {
  /* make sure tooltipps are displayed below report details foldout*/
  z-index: unset !important;
}

#reports-deckgl-canvas {
  /* make mapbox map and deck.gl map work with each other*/
  pointer-events: none;
  z-index: 2;
}
